import React from "react";
import Card from "react-bootstrap/Card";
import Img from "gatsby-image";
import Colors from "../../styles/Colors";

const NewsImage = ({ imageSharp }) => {
  if (imageSharp) {
    const imgStyle = {
      height: "200px",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    }
    return <Img style={imgStyle} fluid={imageSharp.fluid} />;
  }
  return null;
};

export default (props) => {
  const cardStyle = {
    height: "calc(100% - 20px)",
    textAlign: "left",
    margin: "10px",
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: "0.1rem",
    borderColor: Colors.blue,
    backgroundColor: "rgba(0, 0, 0, 0.1)"
  };

  const titleStyle = {
    minHeight: "3.5rem",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: Colors.blue,
    paddingBottom: ".5rem",
  };

  return (
    <Card className="bg-grey" style={cardStyle}>
      <NewsImage imageSharp={props.imageSharp} />
      <Card.Body>
        <Card.Title style={titleStyle}>{props.title}</Card.Title>
        <Card.Text>
          {props.postDate} <br />
          {props.description} <br />
          <a href={props.linkToVideo} rel="noopener noreferrer" target="_blank">{props.linkToVideo}</a>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}