import React from "react";
import Row from "react-bootstrap/Row";
import NewsCardList from "../components/news/NewsCardList";
import CardContainer from "../components/common/CardContainer";
import PageTitle from "../components/common/PageTitle";
import BackgroundImg from "../components/common/BackgroundImg";
import { useStaticQuery, graphql } from "gatsby";

export default (props) => {
  const newsListData = useStaticQuery(graphql`
  query allNews {
    allNewsYaml {
      nodes {
        linkToVideo
        postDate
        title
        imagePath
      }
    }
    image: file(relativePath: {eq: "newsImg/arete-cosmiques.jpg"})  {
      childImageSharp {
        fluid(maxWidth: 3500, maxHeight: 1900, quality: 80, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`);

  const rowStyle = { alignItems: "unset" };

  return (
    <>
      <BackgroundImg imgFluid={newsListData.image.childImageSharp.fluid} />
      <CardContainer>
        <PageTitle {...props} />
        <Row style={rowStyle}>
          <NewsCardList newsListData={newsListData.allNewsYaml.nodes} />
        </Row>
      </CardContainer>
    </>
  );
}