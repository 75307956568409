import React from "react";
import Col from "react-bootstrap/Col";
import NewsCard from "./NewsCard";
import { useStaticQuery, graphql } from "gatsby";

function getImageSharp(allImages, relativePath) {
  const ImageEdge = allImages.images.edges.find(edge => edge.node.relativePath === relativePath);
  return (ImageEdge === undefined) ? null : ImageEdge.node.childImageSharp;
}

export default ({ newsListData }) => {
  const allImages = useStaticQuery(graphql`
  query newsImagesQuery {
      images: allFile(filter: {relativeDirectory: {eq: "img"}, extension: {in: ["jpg", "jpeg", "png"]}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
          }
        }
      }
    }
  `)
  let dataArray = [];
  let newsCardData = {};
  let listItems = null;
  if (Array.isArray(newsListData) && newsListData.length > 0) {
    newsListData.forEach((news) => {
      newsCardData = {
        title: news.title,
        postDate: news.postDate,
        linkToVideo: news.linkToVideo,
        imageSharp: getImageSharp(allImages, news.imagePath)
      };
      dataArray.push(newsCardData);
    });
    listItems = dataArray.map((data, index) => {
      return (
        <Col xs={12} md={6} lg={4} xl={3}>
          <NewsCard key={index} {...data} />
        </Col>
      );
    });
  }
  return (
    <React.Fragment>
      {listItems}
    </React.Fragment>
  );
}